





















import { Component, Vue } from 'vue-property-decorator';
import { ILocalidadeCreateUpdate } from '@/interfaces/localidade';
import { dispatchCreateLocalidade } from '@/store/localidade/actions';
import { dispatchGetLocalidades } from '@/store/localidade/actions';
import cacheDominiosEstaticos from '@/store/localidade/cache';
import LocalidadeForm from '@/components/forms/LocalidadeForm.vue';

@Component({
  components: {
    LocalidadeForm,
  },
})
export default class CadastrarLocalidade extends Vue {
  public valid = false;
  public localidadeTemplate = {
    id_origem_localidade: 0,
    id_tipo_localidade: 0,
    id_subprefeitura: 0,
    id_distrito: 0,
    id_bairro: 0,
    id_regiao: 0,
    id_tipo_logradouro: 0,
    id_responsavel: 0,
    id_status_implantacao: 0,
    id_tipo_publicidade: 0,
    codigo_localidade: '',
    nome: '',
    referencia: '',
    logradouro: '',
    numero_logradouro: 0,
    complemento_logradouro: '',
    cep: '',
    latitude: 0.0,
    longitude: 0.0,
  };
  public localidade: ILocalidadeCreateUpdate = this.localidadeTemplate;

  public async mounted() {
    await cacheDominiosEstaticos(this.$store);
    this.reset();
  }

  public reset() {
    this.localidade = this.localidadeTemplate;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const cadastrou = await dispatchCreateLocalidade(this.$store, this.localidade);
      if (cadastrou) {
        dispatchGetLocalidades(this.$store, 0);
        this.$router.push('/main/localidade/listar');
      }
    }
  }
}
